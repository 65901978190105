export const dashboardOverviewTableConfig = {
    headers: [
        {
            text: 'Country',
            value: 'country',
            align: 'left',
            width: '75%',
            textWidth: '250px',
            sortable: false,
        },
        {
            text: 'count',
            value: 'count',
            width: '25%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
    ],
}