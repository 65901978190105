export const dashboardTrackedRecommendedTableConfig = {
    headers: [
        {
            text: 'Country',
            value: 'country',
            align: 'left',
            width: '25%',
            textWidth: '25%',
            sortable: false,
        },
        {
            text: 'Tracked',
            value: 'count',
            width: '25%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
        {
            text: 'Recommended',
            value: 'count_recommended',
            width: '25%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
        {
            text: 'Ranking',
            value: 'count_ranking',
            width: '25%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
    ],
}