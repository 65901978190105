export const dashboardCompareTableConfig = {
    headers: [
        {
            text: 'Metrics',
            value: 'key',
            align: 'left',
            width: '45%',
            textWidth: '250px',
            sortable: false,
            headingClass: 'metrics',
        },
        {
            text: 'Current App',
            value: 'application',
            width: '15%',
            textWidth: '15%',
            align: 'right',
            sortable: false,
            headingClass: 'application',
        },
        {
            text: 'Category Top Average',
            value: 'category',
            width: '23%',
            textWidth: '20%',
            align: 'right',
            sortable: false,
            headingClass: 'category',
            tabletText: 'Category Top',
            mobileText: 'Category Top',
        },
        {
            text: 'Difference',
            value: 'compare',
            width: '15%',
            textWidth: '15%',
            align: 'right',
            sortable: false,
            headingClass: 'difference',
        },
    ],
}