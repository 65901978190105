<script>
import {httpRequest} from "@/api";
import {cropImage, getCookie} from "@/utils/utils";
import ProgressBar from "@/components/UI/ProgressBar";

export default {
  name: 'Competitors',
  components: {
    ProgressBar,
  },
  props: {
    currentApp: {
      type: Object,
      required: true,
    },
    currentCountry: {
      type: Object,
      required: true,
    },
  },
  emits: ['app-click'],
  setup() {
    return {};
  },
  data() {
    return {
      competitorsDataLoaded: false,
      competitorsData: [],
    };
  },
  watch: {
    currentApp: {
      handler: async function (newVal, oldVal) {
        if (newVal?.id !== oldVal?.id && oldVal?.id) {
          await this.fetchCompetitorsData(newVal?.id);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchCompetitorsData();
  },
  methods: {
    cropImageProxy() {
      return cropImage
    },
    competitorAppClicked(competitor) {
      this.$emit('app-click', competitor);
    },
    async fetchCompetitorsData(appId) {
      this.competitorsDataLoaded = false;
      let competitorsResult;
      let currAppId = appId ?? (this.currentApp?.app || getCookie('currentAppId'));

      let competitorsUrl = '?app_id=' + currAppId;
      const competitors = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.CUSTOM_COMPETITORS + competitorsUrl + '&is_competitor=1');

      if (competitors?.list?.isArray) {
        competitorsResult = [...competitors?.list];
      } else {
        if (Object.values(competitors?.list)) {
          competitorsResult = Object.values(competitors?.list);
        } else {
          competitorsResult = [];
        }
      }

      let tmp = [];
      competitorsResult.forEach(item => {
        tmp.push(item);
      });
      this.competitorsData = tmp;

      this.competitorsDataLoaded = true;
    }
  },
};
</script>

<template>
  <template v-if="competitorsDataLoaded">
    <template v-if="competitorsData?.length > 0">
      <div class="competitors-items-block">
        <template v-for="(competitor, index) in competitorsData">
          <div class="competitors-item-wrap"
               v-if="index <= 3">
            <div class="competitors-item"
                 @click="competitorAppClicked(competitor)">
              <div class="app-logo">
                <img :src="cropImageProxy()(competitor?.logo, '50x50', currentApp?.store?.key)"
                     :alt="competitor?.title"
                     width="50"
                     height="50">
              </div>
              <div class="app-details">
                <div class="app-title">{{ competitor?.title }}</div>
                <div class="app-category">{{ competitor?.category }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="competitors-count">
        <progress-bar :width="(100 - competitorsData?.length) + '%'"
                      height="8px"
                      bar-type="gradient"/>
        <div class="count">{{ competitorsData?.length }} Apps</div>
      </div>
    </template>
    <template v-else>
      <div class="empty-competitors-block">
        <div class="block-content">
          <div class="block-heading">
            🧐 No competitors selected yet
          </div>
          <div class="block-text">
            Selecting more competitors is essential to working effectively with the semantic core.
          </div>
          <router-link class="goto-link" :to="{name: 'CompetitorsFinder'}">
            <span>Go to Competitors Finder</span>
            <svg-icon icon="arrow"/>
          </router-link>
        </div>
      </div>
    </template>
  </template>
  <template v-else>
    <div class="preloader-wrap">
      <preloader-table-small loader-size="50px"></preloader-table-small>
    </div>
  </template>
</template>

<style scoped lang="scss">

</style>