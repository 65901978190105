<template>
  <div class="app-info-top-block">
    <div class="info-block-heading">
      <div class="details-block">
        <div class="app-logo">
          <template v-if="topAppData.app?.logo">
            <img :src="cropImage(topAppData.app?.logo, '94x94', topAppData?.app?.store)"
                 :alt="topAppData.app?.title"
                 width="94"
                 height="94">
            <a class="store-link"
               :href="topAppData.currentApp?.store_link"
               target="_blank">
              <img v-if="topAppData.currentApp?.store?.key === 'APP_STORE'"
                   src="@/assets/images/icons/app_store.png"
                   alt="Store icon"
                   class="store-icon"
                   width="30"
                   height="30">
              <img v-else
                   src="@/assets/images/icons/google_play.png"
                   alt="Store icon"
                   class="store-icon"
                   width="30"
                   height="30">
            </a>
          </template>
          <template v-else>
            <div class="empty-logo"></div>
          </template>
        </div>
        <div class="app-details">
          <template v-if="topDataIsLoaded">
            <div class="app-name">{{ topAppData.app?.title ?? '' }}</div>
            <div class="app-subtitle" v-if="false">
              Learn how to improve your score
            </div>
          </template>
          <template v-else>
            <div class="skeleton-wrap">
              <div class="skeleton long-skeleton-box"></div>
              <div class="skeleton medium-skeleton-box mobile-hidden"></div>
            </div>
          </template>
        </div>
      </div>
      <div class="aso-score-block">
        <div class="aso-score"
             :class="[setAsoScoreColor(topAppData?.score?.score)]">
          <template v-if="topDataIsLoaded">
            {{ topAppData?.score?.score ?? 0 }}
          </template>
          <template v-else>
            <div class="skeleton aso-score"></div>
          </template>
        </div>
        <div class="text">ASO score</div>
      </div>
    </div>

    <div class="scores-columns-block">
      <div class="scores-col">
        <div class="scores-item">
          <template v-if="topDataIsLoaded">
            <div class="score-heading">
              <div class="score-title">Text</div>
              <div class="score-amount">{{ topAppData?.score?.text }}/100</div>
            </div>

            <progress-bar :width="(100 - topAppData?.score?.text) + '%'"
                          height="8px"
                          bar-type="gradient"/>
          </template>

          <div v-else
               class="skeleton score-skeleton full-length-skeleton-box"></div>
        </div>

        <div class="scores-item">
          <template v-if="topDataIsLoaded">
            <div class="score-heading">
              <div class="score-title">Graphic</div>
              <div class="score-amount">{{ topAppData?.score?.graphic }}/100</div>
            </div>

            <progress-bar :width="(100 - topAppData?.score?.graphic) + '%'"
                          height="8px"
                          bar-type="gradient"/>
          </template>

          <div v-else
               class="skeleton score-skeleton full-length-skeleton-box"></div>
        </div>
      </div>
      <div class="scores-col">
        <div class="scores-item">
          <template v-if="topDataIsLoaded">
            <div class="score-heading">
              <div class="score-title">Reviews</div>
              <div class="score-amount">{{ topAppData?.score?.reviews }}/100</div>
            </div>

            <progress-bar :width="(100 - topAppData?.score?.reviews) + '%'"
                          height="8px"
                          bar-type="gradient"/>
          </template>

          <div v-else
               class="skeleton score-skeleton full-length-skeleton-box"></div>
        </div>

        <div class="scores-item">
          <template v-if="topDataIsLoaded">
            <div class="score-heading">
              <div class="score-title">Visibility</div>
              <div class="score-amount">{{ topAppData?.score?.visibility }}/100</div>
            </div>

            <progress-bar :width="(100 - topAppData?.score?.visibility) + '%'"
                          height="8px"
                          bar-type="gradient"/>
          </template>

          <div v-else
               class="skeleton score-skeleton full-length-skeleton-box"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/UI/ProgressBar";
import {cropImage} from "@/utils/utils";

export default {
  name: "AppInfoTopBlock",
  components: {
    ProgressBar,
  },
  props: {
    topDataIsLoaded: {
      type: Boolean,
    },
    topAppData: {
      type: Object
    }
  },
  setup() {
    function setAsoScoreColor(score) {
      if (score >= 0 && score < 31) {
        return 'red';
      } else if (score >= 31 && score < 41) {
        return 'orange';
      } else if (score >= 41 && score < 61) {
        return 'yellow';
      } else if (score >= 61 && score < 81) {
        return 'light-green';
      } else if (score >= 81) {
        return 'green';
      } else {
        return '';
      }
    }

    return {
      setAsoScoreColor,
      cropImage,
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>