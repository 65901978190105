<template>
  <div class="insight-item">
    <div class="item-heading">
      <div class="item-text">{{ insight?.title }}</div>
      <div class="item-priority">{{ insight?.importance }}</div>
    </div>
    <div class="item-content">
      <div class="text">
        {{ insight?.description }}
      </div>
      <div class="item-locales"
           v-if="insight?.show_locales"
           ref="localesList">
        <div class="locales-word mobile-hidden">Locales:</div>
        <div class="locales-list">
          <template v-for="(locale, index) in getVisibleLocales">
            <span>{{ locale }}</span>
            <template v-if="getVisibleLocales?.length < averageLocaleLength">
              <template v-if="index !== getVisibleLocales?.length - 1">,&nbsp;</template>
              <template v-else>.</template>
            </template>
            <template v-else>
              <template v-if="index !== averageLocaleLength - 1">,&nbsp;</template>
              <template v-else>, ...</template>
            </template>
          </template>
        </div>
        <div class="other-locales-trigger"
             v-if="insight?.locales?.length > averageLocaleLength">
          <div class="trigger-text">other {{ insight?.locales?.length - averageLocaleLength }} locales</div>
          <div class="trigger-content">
            <template v-for="(locale, index) in getRestLocales">
              <span v-if="index !== getRestLocales?.length - 1">{{ locale }},&nbsp;</span>
              <span v-else>{{ locale }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsightsItem",
  props: {
    insight: {
      type: Object
    }
  },
  data() {
    return {
      maxCharsCount: 0,
      longestLocale: 0,
      localesSummaryLength: 0,
      averageLocaleLength: 0,
    }
  },
  methods: {
    getMaxCharsCount() {
      if (window.innerWidth < 767) {
        const maxLocalesWidth = this.$refs.localesList?.clientWidth * 2 - 90;
        return maxLocalesWidth / 15;
      } else {
        const maxLocalesWidth = this.$refs.localesList?.clientWidth - 140 - 70;
        return maxLocalesWidth / 10;
      }
    },
    getLongestLocale() {
      let maxWidth = 0;
      for (const item of this.insight?.locales) {
        maxWidth = item?.length > maxWidth ? item.length : maxWidth;
        this.localesSummaryLength += item?.length;
      }
      return maxWidth;
    },
  },
  mounted() {
    if (this.$refs.localesList) {
      this.maxCharsCount = this.getMaxCharsCount();
      this.longestLocale = this.getLongestLocale();
      const averageChars = Math.round(this.localesSummaryLength / this.insight?.locales?.length);
      this.averageLocaleLength = Math.round(this.maxCharsCount / averageChars);
    }
  },
  computed: {
    getVisibleLocales() {
      return this.insight?.locales?.slice(0, this.averageLocaleLength);
    },
    getRestLocales() {
      const rest = this.insight?.locales?.length - this.averageLocaleLength;
      if (!rest) {
        return;
      }

      return this.insight?.locales?.slice(this.averageLocaleLength);
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>