<template>
  <template v-if="trackingKeywordsData?.length > 0">
    <SimpleInfoTable :items="trackingKeywordsData"
                     :columns="{
                                 country: {header: 'Country', orientation: 'left', sortBy: 'countryName'},
                                 tracked: {header: 'Tracked', sortBy: 'count'},
                                 recommended: {header: 'Recommended', sortBy: 'count_recommended'},
                                 ranking: {header: 'Ranking', sortBy: 'count_ranking'},
                               }"
                     :show-pagination="false"
                     :per-page="perPage"
    >
      <template v-slot:items-country="slotProps">
        <dynamic-image classes="flag"
                       :width="18"
                       :height="14"
                       :size="32"
                       :country-code="slotProps.item?.country"/>
        <span class="country-value"
              @click="redirectTo(slotProps.item?.countryId, 'tracking')">
                    {{ slotProps.item.countryName }}
                  </span>
      </template>
      <template v-slot:items-tracked="slotProps">
        <div class="block-content with-dynamic cursor-link"
             @click="redirectTo(slotProps.item?.countryId, 'tracking')">
          <span class="dynamic"
                v-if="slotProps.item.dynamic !== 0"
                :class="[
                  {red: slotProps.item.dynamic < 0 || slotProps.item.dynamic === 'out'},
                  {green: slotProps.item.dynamic > 0 || slotProps.item.dynamic === 'in'}
                  ]">
            <template v-if="slotProps.item.dynamic > 0">+</template>
            {{ toLocaleStringFormatting(slotProps.item.dynamic) }}
          </span>
          <span class="value">
            {{ slotProps.item.count !== 0 ? toLocaleStringFormatting(slotProps.item.count) : 0 }}
          </span>
        </div>
      </template>
      <template v-slot:items-recommended="slotProps">
        <div class="block-content with-dynamic cursor-link"
             @click="redirectTo(slotProps.item?.countryId, 'recommended')">
                    <span class="dynamic"
                          v-if="slotProps.item.dynamic_recommended > 0"
                          :class="[
                            {red: slotProps.item.dynamic_recommended < 0 || slotProps.item.dynamic_recommended === 'out'},
                            {green: slotProps.item.dynamic_recommended > 0 || slotProps.item.dynamic_recommended === 'in'}
                            ]">
                       <template v-if="slotProps.item.dynamic_recommended > 0">+</template>
                      {{ slotProps.item.dynamic_recommended }}
                    </span>
          <span class="value">
                      {{ toLocaleStringFormatting(slotProps.item.count_recommended) }}
                    </span>
        </div>
      </template>
      <template v-slot:items-ranking="slotProps">
        <div class="block-content with-dynamic cursor-link"
             @click="redirectTo(slotProps.item?.countryId, 'ranking')">
                    <span class="dynamic"
                          v-if="slotProps.item.dynamic_ranking > 0"
                          :class="[
                            {red: slotProps.item.dynamic_ranking < 0 || slotProps.item.dynamic_ranking === 'out'},
                            {green: slotProps.item.dynamic_ranking > 0 || slotProps.item.dynamic_ranking === 'in'}
                            ]">
                       <template v-if="slotProps.item.dynamic_ranking > 0">+</template>
                      {{ slotProps.item.dynamic_ranking }}
                    </span>
          <span class="value">
                      {{ toLocaleStringFormatting(slotProps.item.count_ranking) }}
                    </span>
        </div>
      </template>
    </SimpleInfoTable>
    <div class="link-block"
         v-if="showTrackingLoadMoreBtn">
      <div class="col-link" @click="clickShowAll">
        Show All
      </div>
    </div>
  </template>

  <template v-else>
    <div class="no-data-block">
      <div class="content">
        <span class="emoji">😢</span>
        <span class="text">No keywords added. Add some keywords.</span>
        <div class="btn-block">
          <base-button height="40px"
                       width="182px"
                       :link="true"
                       to="/search-terms/keywords-tracking"
                       font-size="15px">Add some keywords
          </base-button>
        </div>
      </div>
    </div>
  </template>
</template>
<script>

import {defineComponent} from "vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import {getCookie} from "@/utils/utils";
import {httpRequest} from "@/api";

export default defineComponent({
  name: 'TrackingKeywordsDashboardTable',
  emits: [
    'show-all',
  ],
  components: {
    SimpleInfoTable
  },
  props: {
    trackingKeywordsData: Array,
    showTrackingLoadMoreBtn: Boolean,
    perPage: Number,
  },
  methods: {
    clickShowAll() {
      this.$emit('show-all');
    },
    toLocaleStringFormatting(value) {
      return value?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
    },
    async redirectTo(countryCode, type) {
      if (countryCode) {
        await this.setCurrentLatestCountry(countryCode);

        document.body.style.overflow = 'auto';

        if (type === 'tracking') {
          window.location.href = '/search-terms/keywords-tracking';
        } else if (type === 'recommended') {
          window.location.href = '/search-terms/recommended-keywords';
        } else if (type === 'ranking') {
          window.location.href = '/search-terms/ranking-keywords';
        }
      }
    },
    async setCurrentLatestCountry(countryId) {
      const currentAppId = getCookie('currentAppId');
      const url = process.env.VUE_APP_API_URL + 'api/profiles/applications/' + currentAppId + '/' + countryId + '/used';

      await httpRequest('POST', url);
    }
  },
})
</script>
